/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Menu1IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21 12.8H3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h18c.4 0 .8.3.8.8s-.4.8-.8.8zM21 6.8H3c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h18c.4 0 .8.3.8.8s-.4.8-.8.8zM21 18.8H3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h18c.4 0 .8.3.8.8s-.4.8-.8.8z" />
	</svg>
))
Menu1IconSvg.displayName = 'Menu1IconSvg'

const Menu1Icon = forwardRef((props, ref) => <Icon component={Menu1IconSvg} ref={ref} {...props} />)
Menu1Icon.displayName = 'Menu1Icon'

Menu1Icon.defaultProps = { ...Icon.defaultProps }
Menu1Icon.propTypes = { ...Icon.propTypes }

export default Menu1Icon
export { Menu1IconSvg }
