import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const sidebarWidth = `calc(100vw - 45px)`

const HamburgerButton = styled(BHButton)`
	padding: 5px;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.white.main};
	}
`

const SwipeableDrawer = styled(MuiSwipeableDrawer)`
	> .MuiPaper-root {
		width: ${sidebarWidth};
		overflow-x: hidden;
		padding: 54px 0px 26px;
		will-change: auto;
		.NavMenu-root {
			min-height: max-content; //HYDREPLAT-139
			width: 100%;
			.NavSubMenu-root {
				width: calc(100% - 45px);
			}
		}
	}
`

const ButtonClose = styled(BHButton)`
	right: 9px;
	top: 9px;
`

export { ButtonClose, HamburgerButton, SwipeableDrawer }
