import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	> .MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.white.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(19)};
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(22)};
		}
	}
	.MuiBadge-root {
		> .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.white.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(19)};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(22)};
			}
		}
		.MuiBadge-badge .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.white.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		}
	}
`

export { ButtonStyled }
