/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20 21.8c-.4 0-.8-.3-.8-.8v-2c0-1.8-1.5-3.2-3.2-3.2H8c-1.8 0-3.2 1.5-3.2 3.2v2c0 .4-.3.8-.8.8s-.8-.4-.8-.8v-2c0-2.6 2.1-4.8 4.8-4.8h8c2.6 0 4.8 2.1 4.8 4.8v2c0 .4-.4.8-.8.8zM12 11.8c-2.6 0-4.8-2.1-4.8-4.8S9.4 2.2 12 2.2s4.8 2.1 4.8 4.8-2.2 4.8-4.8 4.8zm0-8c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2-1.4-3.2-3.2-3.2z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = { ...Icon.defaultProps }
AccountIcon.propTypes = { ...Icon.propTypes }

export default AccountIcon
export { AccountIconSvg }
