/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 22c-.2 0-.4-.1-.5-.2L2.7 13C1.5 11.8.9 10.2.9 8.6s.7-3.2 1.8-4.4 2.7-2 4.3-2c1.7 0 3.2.7 4.4 1.8l.6.6.5-.5c1.2-1.2 2.7-1.8 4.4-1.8 1.7 0 3.2.7 4.4 1.8 1.2 1.2 1.8 2.8 1.8 4.4 0 1.7-.7 3.2-1.8 4.4l-8.8 8.8c-.1.2-.3.3-.5.3zM7 3.8c-1.2 0-2.4.5-3.4 1.4-1.8 1.8-1.8 4.8.1 6.7l8.3 8.3 8.3-8.3c.9-.9 1.4-2.1 1.4-3.4 0-1.3-.5-2.5-1.4-3.4-.9-.9-2.1-1.4-3.4-1.4-1.3 0-2.5.5-3.4 1.4l-1.1 1.1c-.3.3-.8.3-1.1 0l-1.1-1.1C9.5 4.2 8.3 3.8 7 3.8z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = { ...Icon.defaultProps }
HeartIcon.propTypes = { ...Icon.propTypes }

export default HeartIcon
export { HeartIconSvg }
