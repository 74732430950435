import { styled } from '@mui/system'
import Button from '../../molecules/button'

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.white.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(22)};
	}
`

const Divider = styled('span')`
	background-color: ${({ theme }) => theme.palette.white.main};
	height: 26px;
	width: 1px;
`

export { ButtonIcon, Divider }
